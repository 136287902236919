<template>
	<div>
		<CCard class="p-4">
		<CRow>
			<CCol lg="6">
				<h5 style="font-weight: 600;"> Vehicle Types ({{vehicletypeList.total}})</h5>
			</CCol>
	      	<CCol lg="6">
				  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('vehicle_type').create"
						size="sm" style="margin-right: 10px" 
						color="success" @click="toggleModal()" 
						class="float-lg-right"><i class="fa fa-plus"></i>Add Vehicle Type
					</CButton>
	      		
		    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @vehicletype-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>
		
			
	    <CRow>
			 
		      <CCol lg="12" >    
				  <div class="table-responsive-sm table-responsive-md"> 
			    	<CDataTable
					:loading="isLoading"
				      :items="vehicletypeList.data"
				      :fields="fields"
					   hover
         				  striped
						  oulined
						  small
						  fixed
				    >  
				      	<template #action="{item}">
					        <td style="padding: 5px; width: 100px">
					        	<CDropdown 
					                toggler-text="Select"  
					                size="sm"
					                color="info"
					            >
					               		 <CDropdownItem v-if="config.getPermission('vehicle_type').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o"> Edit</i></CDropdownItem>
						                <CDropdownItem v-if="config.getPermission('vehicle_type').delete" @click="deleteRow(item)"><i class="fa fa-trash"> Delete</i></CDropdownItem> 
					            </CDropdown>
					        </td>
				      	</template>
				    </CDataTable> 
				    <CPagination
			          	:active-page.sync="currentPage"
			          	:pages="Math.ceil(vehicletypeList.total / 20)"
			          	:activePage="currentPage"
			          	@update:activePage="updatePage"
			        />
				  </div>
	      	
	      </CCol>
	    </CRow>
	    <CModal :title="title" :show.sync="formModal" color="info">
           	<form @submit.prevent="submit">
           		<CRow>
					<CCol lg="12">
							<label>Vehicle Type <span class="text-danger">*</span></label>
						<CInput
		                 
		                  placeholder="Enter vehicle type"
		                  v-model="vehicletype_name"
		                  required="true"
		                />
					</CCol>
				</CRow>
	            <div slot="footer" class="w-100">
	            	<CButton size="sm" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right" @click="formModal = false">
	                    Cancel
	                </CButton>
	                <CButton size="sm" type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
	                    Save
	                </CButton>
	                
	            </div>
           	</form>
           	<div slot="footer" class="w-100"></div>
        </CModal>
			</CCard>
	</div>
</template>
<script>
import CTableWrapper from '../../base/Table.vue'
import usersData from '../../users/UsersData'
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'

export default {
	mounted(){
		console.log(config);
		this.getVehicleType();
	},
	data(){
		return{
			config,
			formModal: false,
			vehicletypeList: [],
			vehicletype_name: "",
			fields: [
				{
					key: 'setting_name', 
					label: 'Vehicle Type'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px'}
				}
			],
			currentPage: 1,
			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filterName: "",
			isLoading: false
		}
	},
	name: 'Tables',
	components: { CTableWrapper, Search },
	methods: {
		updatePage(data){
			this.currentPage = data;
			this.getVehicleType();
		},
	    shuffleArray (array) {
	      for (let i = array.length - 1; i > 0; i--) {
	        let j = Math.floor(Math.random() * (i + 1))
	        let temp = array[i]
	        array[i] = array[j]
	        array[j] = temp
	      }
	      return array
	    },

	    getShuffledUsersData () {
	      return this.shuffleArray(usersData.slice(0))
	    },

	    toggleModal(){
	    	this.formModal = !this.formModal;
			this.title = "Add Truck/Vehicle Type";
	    },

	    submit(){
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/setting/"+this.selectedRow.id, {
		    		setting_type: "vehicle_type",
		    		setting_name: this.vehicletype_name
		    	})
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/setting", {
		    		setting_type: "vehicle_type",
		    		setting_name: this.vehicletype_name
		    	})
	    	}
	    	ax.then(response => {

				const {status, message} = response.data;
				if(status == 'duplicate') {
					Swal.fire({
						title: message,
						text,
						icon: 'error', 
					})
					return;
				}
				
	    		var text = 'Vehicle type successfully added!';
	    		if(this.editMode){
	    			text = 'Vehicle type successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
	    		this.formModal = false
	    		this.editMode = false;
	    		this.vehicletype_name = "";
	    		this.getVehicleType();
	    	})
	    },
		search(event){
	    	this.filterName = event.name;
	    	this.getVehicleType();
	    },

	    getVehicleType(){
			this.isLoading = true;
			axios.get(config.api_path+'/setting?setting_type=vehicle_type&setting_name='+this.filterName+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.vehicletypeList = response.data;
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})

	    },

	    updateModal(item){
			this.title = "Update Truck/Vehicle Type";
	    	this.selectedRow = item;
	    	this.vehicletype_name = item.setting_name;
	    	this.formModal = true;
	    	this.editMode = true;

	    },

	    // deleteRow(item){
	    // 	Swal.fire({
		// 		icon:'warning',
		// 		title: 'Are you sure you want to delete this vehicle type?', 
		// 		showCancelButton: true,
		// 		confirmButtonText: `Yes`, 
		// 	}).then((result) => { 
		// 		if (result.isConfirmed) {
		// 			axios.delete(config.api_path+'/setting/'+item.id)
		// 	    	.then(response => {
		// 	    		this.getVehicleType();
		// 	    		Swal.fire({
		// 					title: 'Success!',
		// 					text: "Vehicle type successfully deleted",
		// 					icon: 'success', 
		// 				})
		// 	    	}) 
		// 		}
		// 	})
	    	
	    // }
		 deleteRow(item){
			console.log(item)
	    	Swal.fire({
				icon:"warning",
				title: 'Are you sure you want to delete this vehicle type?', 
				showCancelButton: true,
				confirmButtonText: `Yes`, 
			}).then((result) => { 
				if (result.isConfirmed) {
					this.$showLoading(true)
					axios.delete(config.api_path+'/setting/'+item.id, {
						data : {
							setting_type : 'vehicle_type',
							setting_id : item.id
						}
					})
			    	.then(response => {
						const {status, has_booking} = response.data;
						this.$showLoading(false)
						if(has_booking == 1) {
							Swal.fire({
								title: 'Delete failed!',
								text: "A vehicle type that was already assigned to a booking cannot be deleted. Please contact a support.",
								icon: 'error', 
							})
						}
						else {
							this.getVehicleType();
							Swal.fire({
								title: 'Success!',
								text: "Vehicle type successfully deleted",
								icon: 'success', 
							})
						}
			    		
			    	}) 
				}
			})    
	    }

 	}
}
</script>
